import classNames from 'classnames'
import { Link } from 'gatsby'
import { useMobile } from 'src/hooks/useMobile'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import type { BannersStructure } from '../../types'

export const MainBanners = (dataBanners: BannersStructure[]) => {
  const { screenWidth } = useMobile()

  const HandleClick = (bannerName: string) => {
    const eventTrack = makeEventTrack({
      eventAction: `Encontre seu esporte - banner ${bannerName}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
    localStorage.setItem('originViewListImpressionEvent', `${bannerName}`)
  }

  return (
    <div
      className="flex gap-6 scrollbar-hidden-v2 pl-0 justify-between overflow-x-auto"
      data-testid="section-mainbanners"
    >
      {Object.values(dataBanners).map((item, index) => (
        <div
          key={index}
          className="relative restructure-maxMobile:min-w-[288px]  w-[288px] h-[145px]
          restructure-tablet:w-[254px]
          restructure-tablet:h-[128px]
          restructure-small-desktop:w-[410px]
          restructure-small-desktop:h-[207px]"
        >
          <Link
            to={item.linkUrl}
            className="block relative w-full h-full rounded-md overflow-hidden"
            aria-label={`Link do banner para ${item.title.text}`}
            data-testid={`findyoursport-link-banner-${index}`}
            onClick={() => HandleClick(item.title.text)}
          >
            <img
              src={
                screenWidth < 900
                  ? item.image.tablet
                  : screenWidth < 600
                  ? item.image.mobile
                  : item.image.desktop
              }
              alt={item.title.text}
              className="w-full h-full object-cover"
              data-testid="image-item"
              data-banner-type="Banner Home"
            />
            <span
              className={classNames(
                'absolute left-3 bottom-3 restructure-small-desktop:left-6 restructure-small-desktop:bottom-6 mobile-body-regular-text1 tablet-mobile-body-regular-text1 desktop-heading-title4',
                {
                  'text-restructure-primary-inverted':
                    item.title.textColor === 'branco',
                },
                {
                  'text-restructure-primary': item.title.textColor === 'preto',
                }
              )}
              data-testid="item-name"
            >
              {item.title.text}
            </span>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default MainBanners
