import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'
import { Container } from 'src/components/department/Container'
import { useMobile } from 'src/hooks/useMobile'

import './pictos.scss'

type Item = {
  image: string
  textColor: 'preto' | 'branco'
  text: string
  linkUrl: string
}

type Props = {
  items: Item[]
}

export function Pictos({ items }: Props) {
  const { screenWidth } = useMobile()
  const handleClickLink = (name: string) => {
    if (!window) {
      return
    }

    window.dataLayer = window.dataLayer ?? []

    window.dataLayer.push({
      event: 'event_track',
      event_category: 'clique',
      event_action: name,
      event_label: 'Pagina de departamento',
    })
  }

  return (
    <Container className="restructure-maxMobile:my-8 restructure-maxTablet:my-10 restructure-small-desktop:my-12 !px-0">
      <ul className="pictos flex items-start justify-between max-w-full overflow-x-scroll scrollbar-hide gap-1 pr-2 restructure-small-desktop:pr-0">
        {items?.map((item) => (
          <li
            key={item.text}
            draggable={false}
            data-testid="picto-card"
            className="picto-card"
          >
            <Link
              to={item.linkUrl}
              className="flex flex-col justify-center items-center w-[96px] restructure-small-desktop:w-[124px]"
              onClick={() => handleClickLink(item.text)}
              aria-label={`link para a categoria ${item.text}`}
            >
              <span className="w-[56px] h-[56px] restructure-small-desktop:w-[96px] restructure-small-desktop:h-[96px] flex items-center justify-center bg-restructure-background-secondary rounded-full hover:bg-restructure-background-tertiary active:bg-restructure-background-disabled">
                <Image
                  src={item?.image}
                  height={screenWidth > 900 ? 32 : 24}
                  width={screenWidth > 900 ? 32 : 24}
                  alt={item.text}
                  data-banner-type="Categories Shelf"
                />
              </span>
              <p
                data-testid="picto-text"
                className="text-center mt-3 mobile-body-regular-text3 desktop-body-regular-text2 text-restructure-primary"
              >
                {item.text}
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  )
}
