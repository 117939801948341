import { useCallback, useEffect, useRef, useState } from 'react'
import { Picture } from 'src/components/ui/Image'
import Link from 'src/components/ui/Link'
import { useElementOnScreen } from 'src/hooks/useElementOnScreen'

import type { HeroBanner } from './types'
import { dispatchToDatalayer } from './utils'

interface Props extends HeroBanner {
  index: number
  inSlide?: boolean
  slideActive?: boolean
}

export const Banner = ({
  title,
  image,
  linkUrl,
  text,
  button,
  index,
  inSlide = false,
  slideActive = false,
}: Props) => {
  const style = {
    container: {
      branco: 'bg-[#15181B]',
      preto: 'bg-[#EBEBEB]',
    },
    title: {
      branco: 'text-restructure-primary-inverted',
      preto: 'text-restructure-primary',
    },
    text: {
      branco: 'text-restructure-primary-inverted',
      preto: 'text-restructure-primary',
    },
    button: {
      branco:
        'text-restructure-primary bg-restructure-background-primary hover:bg-restructure-background-neutral-3 active:bg-restructure-background-neutral-5',
      preto:
        'text-restructure-primary-inverted bg-restructure-background-primary-inverted hover:bg-restructure-background-neutral-11 active:bg-restructure-background-neutral-10',
    },
  } as const

  const imageRef = useRef<HTMLImageElement | null>(null)
  const { containerRef, isVisible } = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  })

  const [hasBeenViewed, setHasBeenViewed] = useState(false)

  const dispatchData = useCallback(() => {
    dispatchToDatalayer([
      {
        title,
        currentBannerImage: `${imageRef.current?.currentSrc}`,
        linkUrl,
      },
    ])
    setHasBeenViewed(true)
  }, [title, imageRef, linkUrl])

  useEffect(() => {
    if (hasBeenViewed || !isVisible || !imageRef.current?.currentSrc) {
      return
    }

    if (!inSlide) {
      dispatchData()

      return
    }

    if (!slideActive) {
      return
    }

    dispatchData()
  }, [
    dispatchData,
    hasBeenViewed,
    inSlide,
    isVisible,
    linkUrl,
    slideActive,
    title,
  ])

  return (
    <div data-index={index} ref={containerRef}>
      <Link
        href={linkUrl ?? ''}
        title={title?.text}
        className={`relative block  ${
          style.container[title?.textColor ?? 'branco']
        } !p-0`}
        data-testid="individual-banner"
      >
        <Picture
          data-banner-type="Carousel Home"
          sources={[
            {
              src: image?.mobile ?? '',
              width: 360,
              height: 480,
              media: '(max-width: 766px)',
            },
            {
              src: image?.tablet ?? '',
              width: 766,
              height: 409,
              media: '(max-width: 900px)',
            },
            {
              src: image?.desktop ?? '',
              width: 1440,
              height: 623,
              media: '(min-width: 901px)',
            },
          ]}
          img={{
            src: image?.desktop ?? '',
            width: 1440,
            height: 623,
            alt: image?.altImage ?? '',
            loading: !index ? 'eager' : 'lazy',
            fetchpriority: !index ? 'high' : 'auto',
            ref: imageRef,
          }}
        />
        <div className="absolute z-10 left-0 bottom-0 w-full">
          <div className="mx-4 restructure-tablet:ml-5 w-full flex flex-col items-start max-w-[328px] restructure-tablet:max-w-[492px] restructure-small-desktop:max-w-[627px] restructure-small-desktop:ml-20">
            <h2
              className={`desktop-heading-display tablet-mobile-heading-title2 mobile-heading-title2 ${
                style.title[title?.textColor ?? 'branco']
              }
          w-full
          h-auto
          restructure-tablet:w-[391px]
          restructure-small-desktop:w-[627px]
          `}
            >
              {title?.text}
            </h2>
            {text?.text !== '' && (
              <p
                className={`${
                  style.text[text?.textColor ?? 'branco']
                } desktop-heading-title5 tablet-heading-title5 mobile-heading-title5  restructure-small-desktop:pr-5 block
              mt-[8px]
              w-[328px]
              h-auto
              restructure-tablet:w-[391px]
              restructure-small-desktop:w-[518px]
              restructure-small-desktop:mt-[16px]`}
              >
                {text?.text}
              </p>
            )}

            <button
              className={`${
                style.button[button?.textColor ?? 'branco']
              } flex items-center justify-center rounded-round px-4 min-h-[48px] min-w-[201px] mb-8 mt-6 restructure-tablet:mb-[48px] restructure-small-desktop:mt-[32px] restructure-small-desktop:min-h-[64px] restructure-small-desktop:min-w-[238px] transition-all
              desktop-body-semibold-text1
              tablet-mobile-body-semibold-text2
              mobile-body-semibold-text2`}
            >
              {button?.text}
            </button>
          </div>
        </div>
      </Link>
    </div>
  )
}
