import { array, boolean, object, string } from 'zod'

import type { TertiaryBannerData } from './types'

export function useTertiaryBanner() {
  function validateProps(props: TertiaryBannerData | null) {
    try {
      const schema = object({
        enableSession: boolean(),
        sessionTitle: string(),
        sessionButton: object({
          text: string(),
          linkURL: string(),
        }),
        banners: array(
          object({
            tag: string(),
            label: string(),
            productName: string(),
            productPrice: string(),
            buttonText: string(),
            linkUrl: string(),
            image: object({
              mobile: string(),
              tablet: string(),
              desktop: string(),
            }),
          })
        ),
      })

      schema.parse(props)

      return true
    } catch (err) {
      console.warn('Invalid TertiaryBanner Props', err)

      return false
    }
  }

  return {
    validateProps,
  }
}
