import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

export function sendToDatalayer(action: string) {
  sendEvent(
    makeEventTrack({
      eventAction: action,
      eventPage: 'Home',
    })
  )
}
