import { Link } from 'gatsby'
import { useDeferredValue, useMemo, useState } from 'react'
import Icon from 'src/components/ui/Icon'

import { sendToDatalayer } from '../FindYourSport/utils'
import './esports.scss'

type Props = {
  sportsList: Sport[]
  onClose: () => void
}
export type Sport = {
  title?: string
  url?: string
}

const groupSportsByInitial = (sports: Sport[]) => {
  return sports.reduce((acc, sport) => {
    const firstLetterName = sport?.title?.slice(0, 1).toLowerCase() ?? ''

    if (!acc[firstLetterName]) {
      acc[firstLetterName] = []
    }

    acc[firstLetterName].push(sport)

    return acc
  }, {} as { [key: string]: Sport[] })
}

export function AllSportsModal({ sportsList, onClose }: Props) {
  const [termSearch, setTermSearch] = useState('')
  const deferredTermSearch = useDeferredValue(termSearch)

  const sportsPerLetter = useMemo(() => {
    let sportsActive = sportsList

    if (deferredTermSearch) {
      sportsActive = sportsList.filter((sport) =>
        sport?.title
          ?.toLowerCase()
          .includes(deferredTermSearch.toLocaleLowerCase())
      )
    }

    return groupSportsByInitial(sportsActive)
  }, [deferredTermSearch, sportsList])

  return (
    <div className="bg-restructure-background-primary">
      <div className="flex justify-between pr-6 items-center  border-b-[1px] border-b-[#C3C9CF]">
        <p
          className="p-6
        desktop-body-regular-text1
        mobile-body-regular-text1
        tablet-mobile-body-regular-text1
      "
        >
          Todos os esportes
        </p>
        <button title="close-content" onClick={onClose}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z"
              fill="#15181B"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
              fill="#15181B"
            />
          </svg>
        </button>
      </div>

      <div className="mx-6 mt-6 h-[48px] rounded-[360px] flex items-center px-[12px] bg-restructure-background-secondary">
        <span>
          <Icon
            name="MagnifyingGlass"
            data-testid="store-search-button"
            className="w-[16px] h-[16px] "
          />
        </span>

        <input
          type="text"
          value={termSearch}
          onChange={(e) => setTermSearch(e.target.value)}
          placeholder="Buscar esporte"
          className="flex-1 outline-none pl-[8px] h-10
          bg-restructure-background-secondary
          desktop-body-regular-text3
          mobile-body-regular-text3
          tablet-body-regular-text3"
        />
      </div>

      <div className="pl-6 pr-2 pb-6">
        <div className="findSportsScroll restructure-tablet:max-h-[895px] restructure-small-desktop:max-h-[336px] pr-6 max-h-[600px] overflow-auto">
          {Object.entries(sportsPerLetter).map(([initial, sports]) => (
            <ul className="py-[24px]" key={initial}>
              <li className="flex">
                <p
                  className="mr-6 uppercase
                text-restructure-secondary
                desktop-heading-title5
                tablet-heading-title5
                mobile-heading-title5"
                >
                  {initial}
                </p>
                <ul className="flex flex-wrap gap-4 w-full">
                  {sports.map((sport) => (
                    <li
                      className="text-restructure-secondary w-[132px]"
                      key={sport.title}
                    >
                      <Link
                        to={`${sport.url}`}
                        className="w-full h-full text-left
                        desktop-body-regular-text3
                        mobile-body-regular-text3
                        tablet-body-regular-text3"
                        onClick={() => {
                          sendToDatalayer(`Todos os esportes - ${sport.title}`)
                          onClose()
                        }}
                      >
                        {sport.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  )
}
