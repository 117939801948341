import { useEffect, useMemo } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { Picture } from 'src/components/ui/Image'
import Link from 'src/components/ui/Link'
import { Button } from 'src/components/restructure/ui/Button/Button'
import './style.scss'
import { useMobile } from 'src/hooks/useMobile'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import Skeleton from 'react-loading-skeleton'
import { Container } from 'src/components/department/Container'
import { useElementOnScreen } from 'src/hooks/useElementOnScreen'
import { makeViewPromotion } from 'src/utils/restructure/analytics/makeViewPromotion'
import { makePromoViewG3 } from 'src/utils/restructure/analytics/makePromoViewG3'

import type { BannerOurBrandsType } from '../../types/banner-our-brands'
import { ArrowsSplideOurBrands } from './ArrowsSplideOurBrands'

import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

export default function BannerOurBrands(props: BannerOurBrandsType) {
  const { brands } = props
  const checkBrandsLength = brands.length

  const { containerRef, isVisible, hasBeenViewed, setHasBeenViewed } =
    useElementOnScreen({
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })

  const { screenWidth } = useMobile()

  const perPageSplide = useMemo(
    () =>
      screenWidth < 599 ? 2 : screenWidth >= 600 && screenWidth < 1250 ? 3 : 4,
    [screenWidth]
  )

  const gapItem = useMemo(
    () =>
      screenWidth < 599
        ? 11
        : screenWidth >= 600 && screenWidth < 1250
        ? 16
        : 24,
    [screenWidth]
  )

  useEffect(() => {
    if (!isVisible || hasBeenViewed) {
      return
    }

    const promotions = brands.map(({ title, linkUrl, image }, index) => {
      return {
        promotion_id: linkUrl,
        promotion_name: title.text,
        creative_slot: image.desktop,
        index: index + 1,
      }
    })

    sendEvent(makePromoViewG3(promotions))
    sendEvent(makeViewPromotion(promotions))
    setHasBeenViewed(true)
  }, [isVisible, hasBeenViewed, setHasBeenViewed, brands])

  return (
    <Container className="!px-0">
      <div
        ref={containerRef}
        className=" bg-restructure-background-page relative block
    md:mx-auto
    lg:mx-auto
    "
      >
        <div
          className="flex flex-row text-restructure-primary
      absolute top-1 z-20
      left-4 restructure-tablet:left-0
      desktop-heading-title3
      tablet-heading-title3
      mobile-heading-title3
      "
        >
          Nossas marcas
        </div>
        {checkBrandsLength ? (
          <Splide
            aria-label="Carrosel nossas marcas"
            options={{
              perPage: perPageSplide,
              arrows: true,
              type: 'slide',
              direction: 'ltr',
              pagination: false,
              lazyLoad: true,
              gap: gapItem,
              autoWidth: true,
            }}
            hasTrack={false}
            className="relative"
          >
            <SplideTrack>
              {brands.map((brand, index) => {
                const { title, linkUrl, image } = brand

                return (
                  <SplideSlide
                    key={index}
                    data-index={`element-splid-item${index}`}
                  >
                    <Link
                      data-testid="ourBrand-item-carousel"
                      href={linkUrl}
                      onClick={() => {
                        sendEvent(
                          makeEventTrack({
                            eventAction: `Nossas Marcas - banner ${title.text}`,
                            eventPage: 'Home',
                          })
                        )
                      }}
                    >
                      <div className="relative pt-[54px]">
                        <Picture
                          data-banner-type="Carousel Home"
                          aria-label={`Imagem nossa marca: ${title.text}`}
                          sources={[
                            {
                              src: image.tablet,
                              width: 240,
                              height: 420,
                              media: '(max-width: 899px)',
                            },
                            {
                              src: image.desktop,
                              width: 302,
                              height: 530,
                              media: '(min-width: 900px)',
                            },
                          ]}
                          img={{
                            alt: title.text,
                            src: image.mobile,
                            width: 139,
                            height: 243,
                            className:
                              'object-contain max-w-[139px] restructure-maxTablet:max-w-[240px] rounded-sm restructure-maxTablet:rounded-lg restructure-small-desktop:max-w-[302px]',
                          }}
                        />
                        <div
                          className="flex justify-between absolute bottom-0 w-full p-3 items-center
                      restructure-maxTablet:p-4
                      restructure-small-desktop:p-6
                      max-w-[139px]
                      restructure-maxTablet:max-w-[240px]
                      restructure-small-desktop:max-w-[302px]
                      "
                        >
                          <div
                            className="
                        brand-title
                      text-restructure-primary-inverted
                        desktop-heading-title3
                        mobile-heading-title5
                        "
                          >
                            {title.text}
                          </div>
                          <div>
                            <Button.Icon
                              name="ArrowRight"
                              width={32}
                              height={32}
                              color="#fff"
                              className="border-[1px] border-restructure-borderColor-primary
                        rounded-[360px] p-2
                        restructure-maxMobile:hidden"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SplideSlide>
                )
              })}
            </SplideTrack>
            <ArrowsSplideOurBrands />
          </Splide>
        ) : (
          <Skeleton className="w-full h-[304px] restructure-tablet:h-[420px] restructure-small-desktop:h-[530px]" />
        )}
      </div>
    </Container>
  )
}
