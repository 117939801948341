import { sendEvent } from 'src/utils/restructure/analytics'
import { makePromotionClick } from 'src/utils/restructure/analytics/makePromotionClick'
import { makePromoViewG3 } from 'src/utils/restructure/analytics/makePromoViewG3'
import { makeViewPromotion } from 'src/utils/restructure/analytics/makeViewPromotion'

import type { Banner } from './types'

export const sendPromotionClick = (dataPromotion: Banner, index: number) => {
  const promotion = {
    id: dataPromotion.linkUrl,
    name: `Home | ${dataPromotion.productName}`,
    creative: dataPromotion.image?.desktop ?? '',
    position: `${index}`,
  }

  sendEvent(makePromotionClick([promotion]))
}

export function dispatchToDatalayer(banners: Banner[]) {
  const promotions = banners.map((banner, index) => ({
    promotion_id: banner.linkUrl ?? '',
    promotion_name: `Home | ${banner.productName}`,
    creative_slot: `${banner.image?.desktop}`,
    index: index + 1,
  }))

  sendEvent(makePromoViewG3(promotions))
  sendEvent(makeViewPromotion(promotions))
}
