import { object, string, boolean, array } from 'zod'

import type { FindYourSports } from '../types'

export function useFindYourSports() {
  function validateProps(props: FindYourSports) {
    try {
      const schema = object({
        enableSession: boolean(),
        mainBanners: array(
          object({
            image: object({
              desktop: string(),
              mobile: string(),
              tablet: string(),
            }),
            linkUrl: string(),
            title: object({
              text: string(),
              textColor: string(),
            }),
          })
        ),
        secondaryBanners: array(
          object({
            image: object({
              desktop: string(),
            }),
            linkUrl: string(),
            title: object({
              text: string(),
              textColor: string(),
            }),
          })
        ),
      })

      schema.parse(props)

      return true
    } catch (e) {
      console.warn(e, 'Invalid data for find your FindYourSports')

      return false
    }
  }

  return {
    validateProps,
  }
}
