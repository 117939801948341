import { object, string, array, boolean } from 'zod'
import type { CollectionShelfProps } from 'src/components/department/types/collection-shelf'

export function useCollectionShelf() {
  function validateProps(props: CollectionShelfProps) {
    try {
      const collectionShelfProps = object({
        enableSession: boolean(),
        title: string(),
        collections: array(
          object({
            engine: string(),
            id: string(),
            text: string(),
          })
        ).max(3),
      })

      collectionShelfProps.parse(props)

      return true
    } catch (error) {
      console.error('Invalid CollectionShelf data')

      return false
    }
  }

  return {
    validateProps,
  }
}
