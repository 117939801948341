import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState, useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import ProductCard from 'src/components/product/ProductCardV2'
import { Container } from 'src/components/department/Container'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import {
  sendEvent,
  makeViewListImpressionEvent,
  makeViewItemListEvent,
  makeProductClickEvent,
} from 'src/utils/restructure/analytics'
import type { Ref } from 'react'
import type {
  CollectionShelfProps,
  Collection,
} from 'src/components/department/types/collection-shelf'

import './collection-shelf.scss'

import { useCollectionShelf } from './useCollectionShelf'

const API_URL =
  'https://decathlonstore.myvtex.com/_v2/api/decathlon-search/product/collection'

interface ICollection extends Collection {
  products?: any[]
  hasAlreadyBeenViewed: boolean
}

export function CollectionShelf(data: CollectionShelfProps) {
  const { validateProps } = useCollectionShelf()

  const propsIsValid = validateProps(data)

  const [collections, setCollections] = useState<ICollection[]>(
    data?.collections.map((collection) => ({
      ...collection,
      hasAlreadyBeenViewed: false,
    })) ?? []
  )

  const containerRef: Ref<HTMLDivElement> | undefined = useRef(null)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [showArrows, setShowArrows] = useState(false)

  const activeCollection = collections[activeTab]

  useEffect(() => {
    if (activeCollection && activeCollection.products) {
      setShowArrows(activeCollection.products.length > 4)
    }
  }, [activeCollection])

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        if (
          !activeCollection.hasAlreadyBeenViewed &&
          activeCollection?.products
        ) {
          const items = activeCollection.products.map((item) => ({
            product: item,
            productItem: item.items[0],
            listName: `nossos_queridinhos - ${activeCollection.text}`,
            listId: 'NA',
          }))

          const viewListImpressionEvent = makeViewListImpressionEvent(items, {
            eventOrigin: 'NA',
            actionField: {
              list: `nossos_queridinhos - ${activeCollection.text}`,
            },
          })

          const viewItemListEvent = makeViewItemListEvent(items)

          sendEvent(viewListImpressionEvent)
          sendEvent(viewItemListEvent)

          setCollections((prevState) => {
            return prevState.map((collection) => {
              if (collection.id === activeCollection.id) {
                return {
                  ...collection,
                  hasAlreadyBeenViewed: true,
                }
              }

              return collection
            })
          })
        }

        intersectionObserver.disconnect()
      }
    })

    intersectionObserver.observe(containerRef?.current)
  }, [activeCollection])

  useEffect(() => {
    if (!data.collections) {
      return
    }

    const fetchData = async () => {
      try {
        const requests = data.collections.map((collection) =>
          axios.get(
            `${API_URL}?collection=${collection.id}&engine=${collection.engine}&resultsPerPage=16&source=website`
          )
        )

        const responses = await Promise.all(requests)

        setCollections((prevState) => {
          return prevState.map((collection) => {
            const collectionById = responses.find(
              (response) =>
                response.data.collection.id.toString() === collection.id
            )

            return {
              ...collection,
              products: collectionById?.data.products ?? [],
            }
          })
        })
      } catch (e) {
        console.error(e)
      }
    }

    fetchData()
  }, [data.collections])

  if (!propsIsValid) {
    return <></>
  }

  if (!data.enableSession) {
    return null
  }

  const haveProductsLoaded = activeCollection?.products?.length ?? false

  const indexComponent = uuidv4()

  return (
    <Container
      data-testid="collection-shelf"
      role="region"
      aria-label="sessão vitrines de coleção de produtos"
      className="restructure-tablet:my-[56px] restructure-small-desktop:my-[80px] container-vitrine-queridinhos flex justify-center flex-col"
    >
      <div ref={containerRef} />
      <h3 className="desktop-heading-title3 mobile-heading-title3 text-restructure-primary mb-4 restructure-tablet:mb-5 restructure-small-desktop:mb-6 px-0">
        {data.title}
      </h3>

      <div className="tabs-container mb-4 restructure-tablet:mb-5 restructure-small-desktop:mb-6 px-0">
        <div className="tabs flex relative">
          {collections.map(
            (collection, idx) =>
              collection.products &&
              collection.products.length > 0 && (
                <>
                  <input
                    type="radio"
                    id={`radio-${idx + 1}--${indexComponent}`}
                    name="tabs"
                    checked={activeTab === idx}
                    onChange={() => setActiveTab(idx)}
                  />
                  <label
                    className={`tab ${
                      activeTab === idx
                        ? 'active mr-[14px] restructure-tablet:mr-[12px] restructure-small-desktop:mr-[12px] desktop-body-regular-text1 text-restructure-action rounded-round'
                        : 'mr-[14px] restructure-tablet:mr-[12px] restructure-small-desktop:mr-[12px] desktop-body-regular-text1 text-restructure-secondary'
                    }`}
                    htmlFor={`radio-${idx + 1}--${indexComponent}`}
                  >
                    {collection.text}
                  </label>
                </>
              )
          )}
        </div>
      </div>

      <div className="vitrines">
        <Splide
          options={{
            rewind: false,
            arrows: showArrows,
            breakpoints: {
              4000: {
                perPage: 4,
                perMove: 4,
              },
              900: {
                perPage: 3,
                perMove: 3,
              },
              600: {
                perPage: 2,
                perMove: 2,
              },
            },
          }}
          className="splide-products-queridinhos splide is-initialized splide--slide splide--ltr splide--draggable is-active is-overflow"
        >
          <ProductShelfSkeleton loading={!haveProductsLoaded}>
            {activeCollection.products?.map((product, idx: number) => (
              <SplideSlide key={`product-$${idx}`}>
                <ProductCard
                  product={product}
                  orientation="column"
                  productClickObj={makeProductClickEvent(
                    product,
                    product.items[0],
                    `vitrine ${data.title} - ${activeCollection.text}`
                  )}
                />
              </SplideSlide>
            ))}
          </ProductShelfSkeleton>
        </Splide>
      </div>
    </Container>
  )
}
