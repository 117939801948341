import type { IconProps } from './typesIcons'

export function PlusIcon({ width = 24, height = 24 }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.54163C10.8452 3.54163 11.125 3.82145 11.125 4.16663V15.8333C11.125 16.1785 10.8452 16.4583 10.5 16.4583C10.1548 16.4583 9.875 16.1785 9.875 15.8333V4.16663C9.875 3.82145 10.1548 3.54163 10.5 3.54163Z"
        fill="#3643BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04175 10C4.04175 9.65482 4.32157 9.375 4.66675 9.375H16.3334C16.6786 9.375 16.9584 9.65482 16.9584 10C16.9584 10.3452 16.6786 10.625 16.3334 10.625H4.66675C4.32157 10.625 4.04175 10.3452 4.04175 10Z"
        fill="#3643BA"
      />
    </svg>
  )
}
