import { useEffect, useMemo, useRef } from 'react'
import axios from 'axios'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import type { Options } from '@splidejs/splide'
import { useMobile } from 'src/hooks/useMobile'
import Skeleton from 'react-loading-skeleton'
import type { Product } from 'src/components/ShelfLinx/ShelfBoughtTogether/types'

import ProductCard from '../../../../../product/ProductCardV2'
import type { Showcases } from '../../../types/linx-recommendations'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import 'react-loading-skeleton/dist/skeleton.css'
import './style.scss'

type ShelfGenericProps = {
  item: Showcases | any
  breakpoints?: Options['breakpoints']
}

export const ShelfComponent = ({
  item,
  breakpoints = {
    600: {
      perPage: 2,
      perMove: 2,
    },
    900: {
      perPage: 3,
      perMove: 3,
    },
  },
}: ShelfGenericProps) => {
  const splide = useRef<Splide>(null)
  const observer = useRef<HTMLPreElement>(null)
  const { screenWidth } = useMobile()

  const perPageSplide = useMemo(
    () =>
      screenWidth < 599 ? 2 : screenWidth >= 600 && screenWidth < 1250 ? 3 : 4,
    [screenWidth]
  )

  const gapItem = useMemo(
    () =>
      screenWidth < 599
        ? 12
        : screenWidth >= 600 && screenWidth < 1250
        ? 16
        : 24,
    [screenWidth]
  )

  const options: Options = {
    type: 'slide',
    perPage: perPageSplide,
    gap: gapItem,
    rewind: false,
    arrows: true,
    perMove: perPageSplide,
    breakpoints,
  }

  useEffect(() => {
    if (observer.current) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          axios.get(item.impressionUrl)
        }
      })

      intersectionObserver.observe(observer?.current)

      return () => intersectionObserver.disconnect()
    }

    return () => {}
  }, [item.impressionUrl])

  return (
    <>
      <pre ref={observer} />
      <h2
        className="text-restructure-primary desktop-heading-title3 mobile-heading-title4
        mb-6
        restructure-small-desktop:mb-9"
      >
        {item?.title}
      </h2>
      {item.displays?.[0]?.recommendations.length ? (
        <Splide
          options={options}
          hasTrack={false}
          className="generic-shelf relative"
          aria-label={`Carrosel ${item?.title}`}
          ref={splide}
        >
          <SplideTrack>
            {item.displays?.[0]?.recommendations?.map(
              (product: Product, index: number) => (
                <SplideSlide key={product?.productId}>
                  <ProductCard
                    key={product?.productId}
                    product={product}
                    index={index}
                    orientation="column"
                  />
                </SplideSlide>
              )
            )}
          </SplideTrack>
        </Splide>
      ) : (
        <Skeleton className="w-full h-[245px] restructure-tablet:h-[320px] restructure-small-desktop:h-[431px]" />
      )}
    </>
  )
}

export const Shelf = ShelfComponent
