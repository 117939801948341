import type { PromoG3 } from './types/promoView'
import type { PromotionClickEvent } from './types/promotionClick'

export const makePromotionClick = (
  promotions: PromoG3[]
): PromotionClickEvent => {
  return {
    event: 'promotionClick',
    ecommerce: {
      promoClick: {
        promotions,
      },
    },
  }
}
