import { useContext } from 'react'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import { Container } from 'src/components/department/Container'

import { RecommendationContext } from '../../contexts/recommendations-context'
import type {
  DataRecommendation,
  Showcases,
} from '../../types/linx-recommendations'
import { Shelf } from './Shelf/Shelf'

type ShelfGenericComponentType = {
  position: string
  enableSession?: boolean
}
const replacePositionName = (position: string) => {
  return position
    .replace('topo', 'top')
    .replace('meio', 'middle')
    .replace('baixo', 'bottom')
    .replace('area bonus', 'area-bonus')
}

export const ShelfGenericComponent = ({
  position,
  enableSession = true,
}: ShelfGenericComponentType) => {
  const { dataRecommendation, error, loading } = useContext(
    RecommendationContext
  )

  if (!enableSession) {
    return <></>
  }

  if (!dataRecommendation) {
    return null
  }

  const dataShelf = dataRecommendation.filter(
    (item: DataRecommendation) =>
      item.position === replacePositionName(position) && item.showcases.length
  )

  const recommendations =
    dataShelf.length && dataShelf[0].showcases.length
      ? dataShelf[0].showcases
      : []

  return (
    <>
      {!error && dataShelf.length > 0 && loading && (
        <>
          {recommendations.length > 0 ? (
            <>
              {recommendations.map((item: Showcases) => (
                <Container
                  key={item.id}
                  className="section my-[40px] restructure-tablet:my-[56px] restructure-small-desktop:my-[80px]"
                >
                  <Shelf item={item} />
                </Container>
              ))}
            </>
          ) : (
            <Container className="my-[40px] restructure-tablet:my-[56px] restructure-small-desktop:my-[80px]">
              <ProductShelfSkeleton />
            </Container>
          )}
        </>
      )}
    </>
  )
}
