import classNames from 'classnames'
import { Link } from 'gatsby'
import { useMobile } from 'src/hooks/useMobile'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import type { BannersStructure } from '../../types'

export const SecondaryBanners = (dataBanners: BannersStructure[]) => {
  const { screenWidth } = useMobile()

  const HandleClick = (bannerName: string) => {
    const eventTrack = makeEventTrack({
      eventAction: `Encontre seu esporte - card ${bannerName}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
    localStorage.setItem('originViewListImpressionEvent', `${bannerName}`)
  }

  return (
    <div
      className={classNames(
        'mt-6 gap-3 restructure-small-desktop:gap-6 flex flex-wrap restructure-small-desktop:flex-nowrap'
      )}
      data-testid="section-secondarybanners"
    >
      {Object.values(dataBanners).map((item, index) => (
        <div
          key={index}
          className={classNames(
            'overflow-hidden cards relative restructure-small-desktop:mb-0 bg-restructure-background-secondary rounded-lg restructure-small-desktop:h-[193px] restructure-small-desktop:w-[193px]',
            {
              'w-[calc(50%-8px)]': screenWidth <= 900,
              'w-full': screenWidth > 900,
            },
            {
              'restructure-small-desktop:max-h-[193px] restructure-small-desktop:min-h-[193px]':
                screenWidth <= 900,
            }
          )}
        >
          <Link
            to={item.linkUrl}
            className="flex h-full flex-col justify-between relative
            restructure-small-desktop:h-[193px]
            restructure-small-desktop:w-[193px]"
            aria-label={`Link para ${item.title.text}`}
            data-testid={`findyoursport-link-item-${index}`}
            onClick={() => HandleClick(item.title.text)}
          >
            <div
              className={classNames(
                'my-4 restructure-small-desktop:mt-4 restructure-small-desktop:mb-6 restructure-medium-desktop:mt-6 restructure-medium-desktop:mb-8 text-center mobile-body-semibold-text3 tablet-mobile-body-semibold-text2 desktop-body-semibold-text2',
                {
                  'text-restructure-primary-inverted':
                    item.title.textColor === 'branco',
                },
                {
                  'text-restructure-primary': item.title.textColor === 'preto',
                }
              )}
            >
              {item.title.text}
            </div>
            <img
              className="w-full h-full object-cover absolute hidden restructure-small-desktop:block"
              src={item.image.desktop}
              alt={item.title.text}
              data-testid="image-item"
              data-banner-type="Categories Shelf"
            />
          </Link>
        </div>
      ))}
    </div>
  )
}
