import type { ButtonHTMLAttributes, ReactNode } from 'react'

type Variant =
  | 'primary'
  | 'primary-inverted'
  | 'secondary'
  | 'secondary-inverted'
  | 'convertion'
  | 'tertiary'

type Size = 'small' | 'medium' | 'large' | 'icon'

interface ButtonRootProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  variant?: Variant
  size?: Size
  linkProps?: React.AnchorHTMLAttributes<HTMLAnchorElement>
  sizeAsBreakpoints?: boolean
}

const classNamesStyles = {
  primary:
    'bg-restructure-background-primary-inverted text-restructure-primary-inverted active:bg-restructure-background-neutral-10 disabled:bg-restructure-background-neutral-6 disabled:text-restructure-tertiary',
  'primary-inverted':
    'bg-restructure-background-primary text-restructure-primary hover:bg-restructure-background-tertiary active:bg-restructure-background-neutral-5 disabled:bg-restructure-background-neutral-4 disabled:text-restructure-disabled',
  secondary:
    'border border-restructure-background-neutral-5 text-restructure-primary hover:bg-[#15181b] hover:bg-opacity-5 active:bg-[#15181b] active:bg-opacity-10 disabled:text-restructure-disabled bg-transparent',
  'secondary-inverted':
    'border border-restructure-background-neutral-5 text-restructure-primary-inverted hover:bg-white hover:bg-opacity-[0.15] active:bg-white active:bg-opacity-25 disabled:text-restructure-disabled bg-transparent',
  convertion:
    'bg-restructure-background-action-1 hover:bg-restructure-background-action-1-hover text-restructure-primary-inverted active:bg-restructure-background-action-3 disabled:bg-restructure-background-action-4 disabled:text-restructure-secondary-inverted',
  tertiary:
    'bg-restructure-background-secondary text-restructure-action hover:bg-restructure-background-tertiary active:bg-restructure-background-neutral-4 disabled:bg-transparent disabled:text-restructure-blue-2',
} as const

const sizeStyles = {
  small: ' min-h-[32px] text-font-size-3 min-w-[32px]',
  medium: ' min-h-[48px] text-font-size-4 min-w-[40px]',
  large: 'min-h-[56px] text-font-size-5 min-w-[64px]',
  icon: '',
} as const

const breakpointStyles = {
  small: 'min-h-[32px] text-font-size-3 min-w-[32px]',
  medium:
    'restructure-tablet:min-h-[48px] restructure-tablet:text-font-size-4 restructure-tablet:min-w-[40px]',
  large:
    'restructure-medium-desktop:min-h-[56px] restructure-medium-desktop:text-font-size-5 restructure-medium-desktop:min-w-[64px]',
  icon: '',
} as const

function applyBreakpoints() {
  return Object.values(breakpointStyles).join(' ')
}

export const ButtonRoot = ({
  children,
  className,
  variant = 'primary',
  size = 'small',
  linkProps = undefined,
  sizeAsBreakpoints = false,
  ...rest
}: ButtonRootProps) => {
  const applyStyle = `${classNamesStyles[variant]} ${
    sizeAsBreakpoints ? applyBreakpoints() : sizeStyles[size]
  } ${className}`

  if (linkProps) {
    return (
      <a
        className={`font-inter flex items-center justify-center rounded-round py-1 px-4 font-semibold transition-all gap-2 ${applyStyle}`}
        {...linkProps}
      >
        {children}
      </a>
    )
  }

  return (
    <button
      className={`font-inter flex items-center justify-center rounded-round py-1 px-4 font-semibold transition-all gap-2 ${applyStyle}`}
      {...rest}
    >
      {children}
    </button>
  )
}
