import Section from 'src/components/sections/Section'

import { Banner } from './Banner'
import { HeroSlider } from './HeroSlider'
import type { HeroBannerHome } from './types'
import { useHeroBanner } from './useHeroBanner'

import './style.scss'

export const HeroBanner = (props: HeroBannerHome) => {
  const { banners, enableSession } = props

  const { validateProps } = useHeroBanner()

  const propsIsValid = validateProps(props)

  if (!propsIsValid) {
    return null
  }

  if (!enableSession || banners.length < 1) {
    return null
  }

  if (banners.length === 1) {
    return (
      <Section className="hero-banner" data-testid="hero-banner">
        <Banner {...banners[0]} index={0} />
      </Section>
    )
  }

  return (
    <Section className="hero-banner" data-testid="hero-banner">
      <HeroSlider banners={banners} />
    </Section>
  )
}
